/* eslint-disable */
import React from 'react'
import Helmet from '../../../components/Helmet'

import { graphql } from 'gatsby'

import bookClub from '../../../images/heroes/bookclub.jpg'
import bookClubMobile from '../../../images/heroes/bookclub-mobile.jpg'
import coupleWithLaptop from '../../../images/people/couple-with-laptop.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../../images/brand-logos/aetna.svg'
import cigna from '../../../images/brand-logos/cigna.svg'
import regence from '../../../images/brand-logos/regence.svg'
import united from '../../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../../images/brand-logos/wellcare.svg'
import mutual from '../../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  AvailabilityForm,
  Billboard,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../../components/Header'
import footer from '../../../components/Footer'
import headerData from '../../../data/headerData.js'
import isCallCenterOpen from '../../../../../../utils/isOpen'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-dsnplp']

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title: 'Medicare Advantage PPOs | HealthcarePlans.com',
          description:
            'Medicare Preferred Provider Organizations (PPOs) allow you to choose both in-network and out-of-network providers. Consult with our experts about Medicare PPOs.',
          canonical:
            'https://www.healthcareplans.com/plans/medicare-advantage/ppo',
          robots: 'follow,index',
        },
        path: '/plans/medicare-advantage/ppo',
        promoCode: '170329',
        customBreadCrumbs: [
          {
            shortName: 'Home',
            path: '/',
          },
          {
            shortName: 'Plans',
            path: '/plans',
          },
          {
            shortName: 'Medicare Advantage',
            path: '/plans/medicare-advantage',
          },
        ],
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <div className="hero-content">
          <div className="wrapper">
            <Billboard
              variant="full"
              textColor="Dark"
              image={
                <img
                  src={bookClub}
                  alt="a few elderly people attend a book club and smile"
                />
              }
              mobileImage={
                <img
                  src={bookClubMobile}
                  alt="a few elderly people attend a book club and smile"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Explore Medicare Advantage Preferred Provider Organization
                    (PPO) plans
                  </Typography>

                  <Typography variant="body">
                    Medicare Advantage Preferred Provider Organization (PPO)
                    plans provide private-sector coverage that ensures
                    policyholders receive all that’s covered by Medicare Parts A
                    & B, plus additional benefits.
                  </Typography>

                  {isCallCenterOpen() ? (
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="primary"
                      className="margin-x-auto-small"
                      requestId={requestId}
                    >
                      Call Now {rotatedNumber}
                    </LinkButton>
                  ) : (
                    <AvailabilityForm
                      placeholderText="Zip Code"
                      buttonText="Find a Medicare D-SNP Plan"
                      buttonColor="primary"
                      butttonBackgroundColor="black"
                      className="avail-form"
                    />
                  )}
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos bg-lightgray"
          mainContent={
            <Typography variant="h2">
              We partner with the top insurance providers{' '}
            </Typography>
          }
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <SplitContent
          className="reverse-desktop"
          alignImageToBottom={false}
          image={
            <img src={coupleWithLaptop} alt="an elderly couple sit on couch " />
          }
          mainContent={
            <>
              <Typography variant="h2">
                What is a Medicare Advantage PPO?
              </Typography>
              <Typography variant="body">
                Many Medicare Advantage insurance providers offer two types of
                plan structures: Preferred Provider Organization (PPO) and
                Health Maintenance Organization (HMO). HMO plans require that
                policyholders use their network of hospitals, doctors, and other
                healthcare professionals with exceptions only for emergencies.
                PPO plans, on the other hand, have similar networks but
                policyholders are not required to use in-network providers.
              </Typography>
              <Typography variant="body">
                Medicare Advantage PPO plans offer more freedom of choice than
                HMO plans. If you use their in-network providers, your costs may
                be lower, but you are much less limited in choosing your care,
                and will also have better and more flexible coverage while
                traveling.
              </Typography>
            </>
          }
        />

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">
                Medicare PPO benefits and features
              </Typography>
            </>
          }
          alignMainContent="center"
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../../../images/illustrations/van.svg"
                  alt="dollar icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Travel coverage</Typography>
                <Typography variant="body">
                  Unlike HMO plans, PPO plans often provide coverage for health
                  care while you’re away from home.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/illustrations/prescription-drugs.svg"
                  alt="prescription drugs icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Prescription Drug Coverage</Typography>
                <Typography variant="body">
                  Some Medicare Advantage PPO plans cover prescribed
                  medications.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/illustrations/dental.svg"
                  alt="dental care icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Dental Coverage</Typography>
                <Typography variant="body">
                  Preventative dental care, such as checkups and cleanings, may
                  be covered.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/illustrations/glasses.svg"
                  alt="car icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Vision Coverage</Typography>
                <Typography variant="body">
                  Eye exams and other vision benefits may be available with Part
                  C PPO plans.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                Compare Medicare Part C plans
              </Typography>
            </>
          }
          alignMainContent="center"
          style={{
            color: '#1b0b54',
          }}
        >
          <div className="comparison-table bg-white">
            <table className="table-main">
              <thead>
                <td>Coverage</td>
                <td>HMO Plans</td>
                <td>PPO Plans</td>
                <td>SNPs</td>
              </thead>
              <tbody>
                <tr>
                  <td>Charges monthly premium</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Covers prescription drugs</td>
                  <td>Sometimes</td>
                  <td>Sometimes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Allows out-of-network coverage</td>
                  <td>Sometimes</td>
                  <td>Yes</td>
                  <td>Sometimes</td>
                </tr>
                <tr>
                  <td>Requires primary care physician</td>
                  <td>Sometimes</td>
                  <td>No</td>
                  <td>Sometimes</td>
                </tr>
                <tr>
                  <td>Requires referrals for specialist care</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>Sometimes</td>
                </tr>
              </tbody>
            </table>
          </div>
        </VariableContent>

        <a
          href="https://www.trustpilot.com/review/clearlinkinsurance.com"
          target="_blank"
        >
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <span className="show-on-desktop">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-desktop.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
                <span className="show-on-mobile">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-mobile.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
              </>
            }
          />
        </a>

        <VariableContent
          backgroundColor="primary"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">Give us a call</Typography>
              <Typography variant="body">
                Our licensed agents are available Monday through Friday from
                6:00 am to 5:00 pm MT.
              </Typography>
              <LinkButton
                variant="feature"
                to={`tel:${rotatedNumber}`}
                color="white"
                className=""
                requestId={requestId}
              >
                <span className="text-primary">
                  Call {rotatedNumber} <br />
                  TTY 711
                </span>
              </LinkButton>
            </>
          }
        ></VariableContent>

        <VariableContent
          alignMainContent="left"
          mainContent={
            <Typography variant="h2">
              Frequently asked questions about Medicare Advantage PPOs
            </Typography>
          }
          className="faq-section-padding"
          style={{ color: '#1B0B54' }}
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  title: 'Who is eligible for Medicare PPO plans?',
                  content: (
                    <>
                      <Typography>
                        Medicare Part C plans, including Medicare Advantage PPO
                        plans, are available to anyone eligible for Original
                        Medicare (Parts A & B, through the federal government).
                        Medicare is available to U.S. citizens qualifying by age
                        or special qualifying conditions.
                      </Typography>
                      <List>
                        <ListItem>
                          <strong>Age:</strong> If you are a citizen of the
                          United States who has been living in the U.S. for the
                          previous 5 years and are 65 years of age (or
                          approaching your 65th birthday) you are eligible to
                          sign up for Medicare.
                        </ListItem>
                        <ListItem>
                          <strong>Qualifying Conditions:</strong> You may
                          qualify for early benefits if
                          <List>
                            <ListItem>
                              you are disabled and have received Social Security
                              benefits for at least 24 consecutive months;
                            </ListItem>
                            <ListItem>
                              you suffer from kidney failure, also known as
                              End-Stage Renal Disease (ESRD), and have had a
                              kidney transplant or been on dialysis for 3
                              months; or
                            </ListItem>
                            <ListItem>
                              you have amyotrophic lateral sclerosis (ALS or Lou
                              Gehrig’s disease).
                            </ListItem>
                          </List>
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'How do I enroll in a Medicare PPO?',
                  content: (
                    <>
                      <Typography>
                        Enroll in a Medicare Advantage PPO plan during a
                        qualified{' '}
                        <a href="/resources/medicare-enrollment-101/">
                          Medicare Enrollment Period
                        </a>
                        . Here are a few of the most common times of year to
                        enroll in, or change, your Medicare Part C coverage:
                        <br />
                        <br />
                        <List>
                          <ListItem>
                            <a href="/resources/when-is-medicare-advantage-annual-enrollment/">
                              Annual Enrollment Period
                            </a>{' '}
                            - October 15-December 7 each year, available to
                            anyone Medicare-eligible
                          </ListItem>
                          <ListItem>
                            <a href="/resources/gep-oep-enrollment-periods/">
                              Medicare Advantage Open Enrollment Period
                            </a>{' '}
                            - January 1-March 31 each year, available to anyone
                            already enrolled in a Medicare Advantage plan
                          </ListItem>
                          <ListItem>
                            <a href="/resources/special-enrollment-period-chart/">
                              Special Enrollment Periods
                            </a>{' '}
                            - may occur throughout the year, triggered by
                            qualifying events
                          </ListItem>
                        </List>
                      </Typography>
                      <Typography>
                        To enroll in a plan, you can compare online or speak to
                        an agent by calling{' '}
                        <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>{' '}
                        between 6:00 am and 5:00 pm MT, Monday through Friday.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'Do Medicare PPO plans charge a monthly premium?',
                  content: (
                    <Typography>
                      Yes, Medicare Advantage PPO plans do charge a monthly
                      premium. Monthly premiums associated with PPO plans are
                      generally higher than those associated with HMO plans due
                      to the broader range of coverage.
                    </Typography>
                  ),
                },
                {
                  title: 'Are prescription drugs covered by Medicare PPOs?',
                  content: (
                    <Typography>
                      Some, but not all, Medicare Advantage PPO plans cover
                      prescription drugs. If you prefer an HMO plan with
                      prescription drug coverage, speak to an agent by calling{' '}
                      <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>{' '}
                      between 6:00 am and 5:00 pm MT, Monday through Friday. Let
                      the agent know your preference and they can help you find
                      the plan that fits.
                    </Typography>
                  ),
                },
                {
                  title:
                    'Can I use any doctor or hospital that accepts Medicare for covered services?',
                  content: (
                    <>
                      <Typography>
                        Unlike HMO plans, which have required provider networks,
                        PPO plans allow you to use any doctor or hospital that
                        accepts Medicare for covered services. However, if you
                        use a provider or hospital outside the PPO network,
                        there may be additional costs for care.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'Do I need to choose a primary care provider (PCP)?',
                  content: (
                    <>
                      <Typography>
                        No, with PPO plans you do not need to choose a primary
                        care provider (PCP).
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'Do I have to get a referral to see a specialist?',
                  content: (
                    <>
                      <Typography>
                        No, with PPO plans you do not need to get a referral to
                        see a specialist.
                      </Typography>
                    </>
                  ),
                },
                {
                  title:
                    'Are my Medicare PPO options limited based on the state I live in?',
                  content: (
                    <>
                      <Typography>
                        All Medicare Advantage plans must offer equivalent
                        coverage to that provided by Original Medicare (Parts A
                        & B) through the federal government. What varies
                        plan-by-plan are the additional benefits available and
                        their associated costs.
                      </Typography>
                      <Typography>
                        To learn what’s available in your area, speak to an
                        agent by calling{' '}
                        <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>{' '}
                        between 6:00 am and 5:00 pm MT, Monday through Friday.
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Medicare resources</Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../../../images/people/senior-couple-dog.jpg"
                  alt="cute elderly couple"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/plans/medicare-advantage/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Advantage Plans
                  </a>
                </Typography>
                <Typography variant="body">
                  Learn about Medicare Advantage, also referred to as Medicare
                  Part C, coverage.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/people/grandparents-on-couch-with-grandkids.jpg"
                  alt="elederly couple sit with grandkids on couch and smile"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/when-is-medicare-advantage-annual-enrollment/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Annual Enrollment Period 101
                  </a>
                </Typography>
                <Typography variant="body">
                  When is the Medicare Annual Enrollment Period? Get answers to
                  this and other AEP-related questions.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/people/biking-on-beach.jpg"
                  alt="an elderly couple rides bicycles on the beach"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/medicare-enrollment-101/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Enrollment Overview
                  </a>
                </Typography>
                <Typography variant="body">
                  Get fast facts about Medicare Enrollment, including when you
                  qualify and when to enroll.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
